.App {
  flex: 1;
  height: 100vh;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/*

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.BottomButton{
  width: 20%;  
  background-color: #8B7F7F;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

.HeaderButton{
  width: 20%;  
  background-color: #800080;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}



  .fixed2{
    position: fixed;
    top: 0;
	  left:100;
    width: 35%;
  }

  .fixed3{
    position: fixed;
    bottom: 0;
    width: 100%;
  }

  fieldset {
    margin:auto;
    width: 480px;
    padding: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    flex-direction: column;
    background-color: #5f249f;
    color: #FFFFFF;
  }
  
  label {
    margin-bottom: 8px;
    font-size: 14px;
    text-align: left;
  }
  
  sup {
    color: red;
    top: -2px;
    font-size: 95%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  input,
select {
  border-radius: 4px;
  border: 1px solid #ccc;
  height: 32px;
  padding: 0 8px;
}

.Field {
  position: relative;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
}

.FieldError {
  position: absolute;
  bottom: -6px;
  color: red;
  font-size: 12px;
}

.Row {
  display: flex;
  flex-direction: row;
}

button {
    padding: 12px;
    width: 240px;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: #5f249f;
    color: white;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
  }

  button:disabled,
  button[disabled] {
    border: 1px solid #999999;
    background-color: #FFFFFF;
    color: #5f249f;
    cursor: not-allowed;
  }
  */
